@use '@angular/material' as mat;

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import '@splidejs/splide/css/core';

@import '@mdi/font/css/materialdesignicons.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'mixins';
@import 'typography';

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

// TODO: move palette to shared or another folder
$primary-palette: (
  50: #feeee3,
  100: #fcd5b9,
  200: #fbba8b,
  300: #f99e5c,
  400: #f78939,
  500: #f67416,
  600: #f56c13,
  700: #f36110,
  800: #f2570c,
  900: #ef4406,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$warn-palette: (
  50: #fee8eb,
  100: #fcc5ce,
  200: #fa9fae,
  300: #f7788d,
  400: #f65b74,
  500: #f43e5c,
  600: #f33854,
  700: #f1304a,
  800: #ef2841,
  900: #ec1b30,
  A100: #ffffff,
  A200: #ffecee,
  A400: #ffb9bf,
  A700: #ffa0a8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($primary-palette, 500, 100, 700);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-warn: mat.define-palette($warn-palette, 500, 100, 700);

$my-theme: mat.define-light-theme(
    (
      color: (
        primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn,
      ),
      typography:
      mat.define-typography-config(
        $font-family: 'Lato',
      ),
      density: 0,
    )
);

@include mat.all-component-themes($my-theme);

:root {
  --primary-light: #5eead4; // not yet
  --primary: #f47621;
  --primary-dark: #0f766e; // not yet
  --secondary-light: #bae6fd; // not yet
  --secondary: #0ea5e9; // not yet
  --secondary-dark: #0369a1; // not yet
  --blue: #586770;
  --blue-dark: #333f48;
  --white: #ffffff;
  --black: #000000;
  --text-02: #525252;
  --text-03: #a8a8a8;
  --field-01: #f4f4f4;

  --brand-active: #f67416;
  --brand-background: #fff6eb;
  --brand-destructive-active: #e21d48;
  --brand-selected: #fdba72;
  --brand-success: #66a50d;
  --brand-destructive-hover: #f43e5c;

  --mode-base-00: #fff;
  --mode-base-50: #fcfcfc;
  --mode-base-100: #f4f4f5;
  --mode-base-200: #e4e4e7;
  --mode-base-400: #a1a1aa;
  --mode-base-600: #52525b;

  --mode-alpha-100: rgba(173, 173, 173, 0.2);
  --mode-alpha-200: rgba(50, 62, 72, 0.2);
  --mode-alpha-400: #323E4866;
  --mode-alpha-600: #52525b;
  --mode-alpha-700: #323e48b2;
  --mode-alpha-900: #323e48;

  --colors-base-00: #fff;
  --colors-base-900: #323e48;

  --colors-orange-50: #fff6eb;
  --colors-orange-500: #f67416;
  --colors-orange-200: #fed6a9;

  --colors-gray-900: #202024;

  --colors-blue-gray-200: #e1e7ef;
  --colors-blue-gray-400: #94a3b8;

  --space-3: 4px;
  --space-4: 8px;
  --space-5: 10px;
  --space-6: 12px;
  --space-7: 16px;
  --space-8: 20px;
  --space-9: 24px;
  --space-11: 32px;
  --space-13: 40px;
  --space-15: 48px;
  --space-16: 56px;
  --space-17: 64px;
  --space-18: 80px;
  --space-24: 160px;

  --radius-radi-2: 6px;
  --radius-radi-4: 8px;
  --radius-radi-6: 12px;
  --radius-radi-8: 22px;

  --md: 640px;

  --income-border: #bedbfe;
  --income-background-color: #f1f5f9;

  --expenses-border: #e4e4e7;
  --expenses-background-color: #f4f4f5;

  --liabilities-border: #fbd0e8;
  --liabilities-background-color: #fce8f4;

  --assets-border: #d9f99f;
  --assets-background-color: #ebfcca;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  height: 100%;
}

ul {
  list-style-type: none;
}

a {
  color: var(--mode-alpha-900, #323e48);
  font-weight: 500;
  text-underline-offset: 0.25em;
}

a.secondary {
  color: var(--secondary-dark, #0369a1);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.button-content-wrapper {
  display: flex;
  align-items: center;
  gap: var(--space-6, 12px);
  padding: calc(var(--space-4) - 1px) 0;

  > span {
    text-align: left;
    @include text-lg(semibold);
  }
}

svg-icon-sprite {
  .primary-color {
    color: var(--brand-active);
  }

  .success-color {
    color: var(--brand-success);
  }

  .error-color {
    color: var(--brand-destructive-active);
  }
}

mat-card {
  border-width: 1px;
  border-color: #e4e4e7;

  &.mat-mdc-card {
    --mdc-elevated-card-container-shape: var(--radius-radi-6, 12px);
    --mdc-outlined-card-container-shape: var(--radius-radi-6, 12px);

    --mdc-elevated-card-container-elevation: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }

  .mat-mdc-card-content {
    padding: var(--space-11, 32px);

    &:last-child {
      padding-bottom: var(--space-11, 32px);
    }

    &:first-child {
      padding-top: var(--space-11, 32px);
    }
  }

  &.mat-card--small {
    .mat-mdc-card-content {
      padding: var(--space-7);

      &:last-child {
        padding-bottom: var(--space-9);
      }

      &:first-child {
        padding-top: var(--space-9);
      }
    }
  }
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.mt-20{
  margin-top: 20px;
}

.mt-10{
  margin-top: 10px;
}

.mb-10{
  margin-bottom: 10px;
}

.mb-20{
  margin-bottom: 20px;
}

.mat-mdc-menu-panel {
  &.menu-panel {
    --mat-menu-container-color: #323e48;
    --mat-menu-item-label-text-color: white;
    --mat-menu-container-shape: 0;

    transform: translateY(12px);
    width: 332px;
    padding: var(--space-7, 16px);

    .mat-mdc-menu-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .mat-mdc-menu-item-text {
      width: 100%;
      text-align: center;
    }

    button {
      width: 100%;

      &[acorn-button] {
        margin-top: 12px;
      }
    }

    @include respond(phone) {
      transform: translateY(0);
      position: fixed;
      top: 60px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 60px);
      min-width: unset;
      max-width: unset;

      .mat-mdc-menu-content {
        padding-bottom: var(--space-16);
      }

      button.secondary-color {
        margin-top: auto;
      }
    }
  }
}

.multiline-tooltip {
  white-space: pre-line;
}

.social-menu {
  border-radius: 8px;
  border: 1px solid var(--mode-base-100, #f4f4f5);
  display: flex;
  padding: 0 8px;
  gap: var(--space-2, 2px);
  flex-direction: column;

  @include shadow(sm);

  .mat-mdc-menu-item {
    min-height: 44px;
    color: var(--mode-alpha-700, rgba(50, 62, 72, 0.7)) !important;

    .mat-mdc-menu-item-text {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 8px;
    }

    svg-icon-sprite {
      width: 24px;
      height: 24px;
    }
  }
}

.transparent-desktop-backdrop {
  background-color: transparent;

  @include respond(phone) {
    background-color: var(--mode-alpha-400, rgba(50, 62, 72, 0.4));
  }
}

.landing-page-container {
  display: flex;
  justify-content: center;

  > * {
    max-width: 1312px;
  }

  &.w-100 {
    > * {
      width: 1312px;
    }
  }

  @include respond(phone) {
    > * {
      max-width: 100%;
    }
  }
}

.suffix-select-icon {
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);

  color: var(--mat-select-enabled-arrow-color);

  &.triggered {
    color: var(--brand-selected);
    transform: rotate(180deg);
  }

  &.disabled {
    color: var(--mat-select-disabled-arrow-color);
  }
}

owl-carousel-o.secondary {
  .owl-stage-outer {
    .owl-stage {
      .owl-item {
        padding: 0 var(--space-7, 16px);

        @include respond(ipad) {
          padding: 0 var(--space-4, 8px);
        }
      }
    }

    @include respond(phone) {
      padding: 0;
    }
  }

  .owl-theme {
    .owl-nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .owl-prev,
      .owl-next {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: var(--mode-base-100, #f4f4f5);
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: var(--mode-base-100, #f4f4f5);
        }

        @include respond(ipad) {
          display: none;
        }
      }

      .disabled {
        opacity: 1;
        cursor: not-allowed;
      }
    }

    .owl-dots {
      margin-top: var(--space-11, 32px);

      .owl-dot {
        &.active span,
        &:hover span {
          background: var(--brand-active, #f67416);
        }
      }
    }
  }
}

mat-tab-group.secondary {
  .mdc-tab__text-label {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: var(--mode-alpha-900);
    opacity: 0.7;
  }

  .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label,
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label {
    color: var(--mode-alpha-900);
    opacity: 1;
  }
}

.page-wrapper {
  max-width: 1440px;
  margin: auto;

  @media (max-width: 1440px) {
    padding: 0 var(--space-17, 64px);
  }
}

.quick_links li a, .quick_links .mat-mdc-menu-item-text a{
  color: #fff;
  text-decoration: none;
}

.quick_links_light li a{
  color: var(--mode-alpha-700, rgba(50, 62, 72, 0.7));
  font-weight: 600;
  line-height: 24px;
}

#signupHeader{
  img {
    width: 55%;
    margin: 0 auto;
    display: block;
    margin-top: -88px;
  }

  h1{
    font-weight: 300;

    span{
      color: #f67416;
    }
  }

  #signupContent {
    position: relative;
    top: -35px;

    p{
      text-align: left;
    }
  }

  #signupPoints {
    background: #ececec;
    border-radius: 15px;
    padding: 20px;

    li {
      display: inline-block;
      width: 45%;
      margin: 10px 0;
      background-image: url(./assets/icons/orange-tick-icon.svg);
      background-repeat: no-repeat;
      background-size: 20px;
      padding-left: 25px;
    }
  }
}
@media (max-width: 1000px) {
  .goal-setting-container{
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .goal-setting-container .step-description{
    height: auto !important;
  }

  .navigation-buttons {
      position: fixed;
      bottom: 0;
      width: 100vw;
      z-index: 9999;
      background: #f5f5f5;
      padding: 8px;
      left: 0;
  }
}

.mt-1 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.option-container {
  img.Savings {
    background: var(--mode-base-50, #FCFCFC);
    border: 1px solid var(--mode-base-200, #E4E4E7);
    box-shadow: 0px 1px 2px 0px #0000000F;
    width: 36px !important;
    height: 36px !important;
    padding: 6px 0px 0px 0px !important;
    gap: 0px !important;
    border: 1px 0px 0px 0px !important;
    opacity: 0px;
    border-radius: 12px !important;
  }
}

//loader
.c-spinner {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(243, 117, 33, 0.2);
  border-top: 4px solid #f37521;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.c-spinner-inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #f37521;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.text-align-left {
  text-align: left !important;
}