@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/Gotham-Thin.otf') format('opentype');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/GothamLight.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/GothamBook.otf') format('opentype');
  font-style: normal;
  font-weight: 325;
}

@font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/GothamBold.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}
